.attendance-modal {
    margin-top: 8px;
}
.attendance-modal .field {
    margin-bottom: 12px;
}
.attendance-modal .field label {
    font-size: 14px;
    display: block;
    text-align: left;
    margin-bottom: 4px;
}
.attendance-modal .field input {
    margin-top: 0;
}