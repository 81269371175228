.react-datepicker__input-container input:focus {
  outline: none;
  border: 1.5px solid #ff971a;
}

.react-datepicker__input-container input {
  /* width: 100%; */
  /* width: 100px; */
  /* padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px); */
  padding: 4px 4px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.react-datepicker__input-container input {
  /* width: 110px; */
  font-size: 14px;
}
/* 
.react-datepicker__input-container {
  width: 110px !important;
}
.react-datepicker-wrapper {
  width: 110px !important;
}
*/
.react-datepicker__input-container input::placeholder {
  font-size: 12px;
}

.react-datepicker-wrapper {
  /* width: 40% !important; */
}

/* custom input */
.example-custom-input {
  background: url("../../assets/calenderIcon.png") no-repeat right;
  background-position: 92%;
  background-size: 15px;
  /* width: 300px; */
  height: 36px;
  padding: 0 10px;
  font-size: 14px;
}

.example-custom-input:focus-visible {
  border-bottom-color: #b51148;
  outline: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  /* background-color: #b51148; */
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px white;
  /* border: 1px solid #b51148; */
  /* margin: 0.066rem; */
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #b51148;
  border-radius: 50%;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
}

.example-datepicker-class .react-datepicker__today-button {
  color: #b51148;
  background-color: white;
  border-top: 0px;
  text-align: right;
  margin-right: 20px;
}

.some-custom-class2 {
  position: absolute !important;
  right: 130px !important;
}
.some-custom-class1 {
  position: absolute !important;
  left: 15px !important;
}
